import { css, jsx } from '@emotion/core';
import React from 'react';

const cardStyle = css`
  box-shadow: 0 6px 12px 0 rgba(35, 32, 32, 0.1);
  background-color: #ffffff;
  border-radius: 4px;
`;

const Card = ({ children, ...props }) => (
  <div css={cardStyle} {...props}>
    {children}
  </div>
);

export default Card;
