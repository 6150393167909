import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
  panelImage: css`
    height: 234px;
    width: 100%;
    object-position: center;
    object-fit: cover;
  `,
};

const TopicCardImage = props => {
  const { imageData, showImage, css: imageCss } = props;

  if (!showImage) {
    return null;
  }

  const { ImageId, ImageCaption } = imageData;
  const imgSrc = ImageId
    ? `${process.env.NEW_API_URL}image/file?area=c&id=${ImageId}`
    : '/static/topicCardDefaultImg.jpg';

  return <img css={imageCss || style.panelImage} src={imgSrc} alt={ImageCaption} />;
};

TopicCardImage.propTypes = {
  imageData: PropTypes.object.isRequired,
  showImage: PropTypes.bool,
};

TopicCardImage.defaultProps = {
  showImage: true,
};

export default TopicCardImage;
