import PropTypes from 'prop-types';
import React from 'react';

import contentStyle from '@/src/cms/contentStyle';

import HeroSlide from './HeroSlide';
import InfoCard from './InfoCard';
import Infographic from './Infographic';
import Paragraph from './Paragraph';
import RelatedTopic from './RelatedTopic';
import Video from './Video';

const COMPONENT_MAP = {
  ID_1: Video,
  ID_2: InfoCard,
  ID_3: Infographic,
  ID_5: RelatedTopic,
  ID_6: Paragraph,
  ID_8: HeroSlide,
};

const Block = ({ componentData, entities }) => {
  const SelectedComponent = COMPONENT_MAP[`ID_${componentData.ComponentTypeId}`];

  if (SelectedComponent) {
    return (
      <SelectedComponent css={contentStyle} componentData={componentData} entities={entities} />
    );
  }
  console.warn('There is no matching component.');
  return undefined;
};

Block.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default Block;
