import { css, jsx } from '@emotion/core';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@/src/shared/components/Button';
import Card from '@/src/shared/components/Card';
import Heading from '@/src/shared/components/Heading';
import Sanitize from '@/src/shared/components/Sanitize';
import TopicCardImage from '@/src/shared/components/TopicCardImage';
import { bp, colors, space } from '@/styles';

const { m } = space;

const heights = {
  small: css`
    width: 100%;
    max-width: none;
    @media ${bp.lg} {
      width: 32%;
      max-width: 368px;
    }
  `,
  medium: css`
    margin-bottom: 20px;
    height: 200px;
    width: 100%;
    max-width: none;
    &:last-child {
      margin-bottom: 0px;
    }

    @media ${bp.sm} {
      max-width: 368px;
      height: auto;
      margin-bottom: 32px;
      width: 32%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
    }
  `,
};

const styles = {
  panelContainer: css`
    overflow: hidden;
    max-height: 410px;
    position: relative;
    width: 100% !important;
    transition: all 0.25s ease-in-out;
    &:hover {
      .u-panel--effect {
        color: ${colors.primaryRed};
      }
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 6px;
      background-color: red;
      transform: translateY(-100%);
      transition: opacity 0.25s ease-in-out;
      opacity: 0;
    }
  `,
  panelBody: css`
    padding: 28px 16px;
    @media ${bp.md} {
      padding: 14px;
    }
    @media ${bp.lg} {
      padding: 28px;
    }
    .u-panel--effect {
      transition: all 0.25s ease-in-out;
    }
  `,
  panelTitle: css`
    margin-bottom: 8px;
  `,
  panelDescription: css`
    font-size: 1.6rem;
    line-height: 28px;
    color: ${colors.secondaryGray};
    margin-bottom: 0px;
  `,
};

const TopicTile = ({ shouldShowImage = true, heightType = 'medium', data }) => {
  return (
    <Card css={[styles.panelContainer, heights[heightType]]}>
      <TopicCardImage showImage={shouldShowImage} imageData={data} />
      <div className="o-panel__body" css={styles.panelBody}>
        <Heading
          css={styles.panelTitle}
          weight="bolder"
          className="u-panel--effect"
          type="3"
          theme="primaryBlue"
        >
          <Sanitize>{data.Title}</Sanitize>
        </Heading>
        <p css={styles.panelDescription}>
          <Sanitize>
            {data.Paragraph && data.Paragraph.length > 100
              ? `${data.Paragraph.substring(0, 85)}...`
              : data.Paragraph}
          </Sanitize>
        </p>
        {(data.AddButton === 'Y' || data.AddButton === 'Yes') && (
          <a
            href={data.Url}
            target={data.OpenInNewTab === 'Yes' || data.OpenInNewTab === 'Y' ? '_blank' : '_self'}
          >
            <Button size="small" css={m.t12}>
              {data.ButtonText}
            </Button>
          </a>
        )}
      </div>
    </Card>
  );
};

TopicTile.propTypes = {
  data: PropTypes.object.isRequired,
  shouldShowImage: PropTypes.bool,
  heightType: PropTypes.string,
};

TopicTile.defaultProps = {
  shouldShowImage: true,
  heightType: 'medium',
};

export default TopicTile;
