import { css, jsx } from '@emotion/core';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Card from '@/src/shared/components/Card';
import Heading from '@/src/shared/components/Heading';
import Sanitize from '@/src/shared/components/Sanitize';
import TopicCardImage from '@/src/shared/components/TopicCardImage';
import { bp, colors, space } from '@/styles';

import TopicTile from './TopicTile';

const { m } = space;

const heights = {
  small: css`
    width: 100%;
    max-width: none;
    @media ${bp.lg} {
      width: 32%;
      max-width: 368px;
    }
  `,
  medium: css`
    margin-bottom: 20px;
    height: auto;
    width: 100%;
    max-width: none;
    &:last-child {
      margin-bottom: 0px;
    }

    @media ${bp.md} {
      max-width: 368px;
      height: 350px;
      display: flex;
      flex-direction: column;
      margin: 12px;
      flex: 1;
      flex-basis: calc(100% / 2 - 24px);
      max-width: calc(100% / 2 - 24px);
    }

    @media ${bp.lg} {
      flex-basis: calc(100% / 3 - 24px);
      max-width: calc(100% / 3 - 24px);
    }
  `,
};
const styles = {
  topicsContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 36px;

    @media ${bp.md} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,
  topic: css`
    margin-bottom: 20px;
    height: 148px;
    width: 100%;
    max-width: none;
    &:last-child {
      margin-bottom: 0px;
    }
    @media ${bp.md} {
      max-width: 368px;
      height: 410px;
      margin-bottom: 32px;
      width: 32%;
    }
  `,
  panelContainer: css`
    overflow: hidden;
    max-height: 410px;
    position: relative;
    width: 100% !important;
    transition: all 0.25s ease-in-out;
    &:hover {
      .u-panel--effect {
        color: ${colors.primaryRed};
      }
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 6px;
      background-color: red;
      transform: translateY(-100%);
      transition: opacity 0.25s ease-in-out;
      opacity: 0;
    }
  `,
  panelBody: css`
    padding: 28px 16px;
    @media ${bp.md} {
      padding: 14px;
    }
    @media ${bp.lg} {
      padding: 28px;
    }
    .u-panel--effect {
      transition: all 0.25s ease-in-out;
    }
  `,
  panelDescription: css`
    font-size: 1.2rem;
    line-height: 1.67;
    color: ${colors.secondaryGray};
    margin-bottom: 0px;
  `,
};

const RelatedTopic = ({ componentData }) => {
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = () => setWindowWidth(window.screen.width);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Card css={[styles.panelContainer, heights['medium']]}>
      <TopicCardImage showImage={windowWidth >= 768} imageData={componentData} />
      <div className="o-panel__body" css={styles.panelBody}>
        <Heading
          weight="bolder"
          className="u-panel--effect"
          type="3"
          theme="primaryBlue"
          tag="a"
          href={componentData.Url}
          target={
            componentData.OpenInNewTab === 'Yes' || componentData.OpenInNewTab === 'Y'
              ? '_blank'
              : '_self'
          }
        >
          <Sanitize>{componentData.Title}</Sanitize>
        </Heading>
        <p css={styles.panelDescription}>
          <Sanitize>
            {componentData.Subheading && componentData.Subheading.length > 100
              ? `${componentData.Subheading.substring(0, 85)}...`
              : componentData.Subheading}
          </Sanitize>
        </p>
      </div>
    </Card>
  );
};

RelatedTopic.propTypes = {};

export default RelatedTopic;
