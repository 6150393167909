import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { bp } from '@/styles';

import TopicTile from './TopicTile';

const styles = {
  topicsContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    @media ${bp.md} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,
  topic: css`
    margin-bottom: 20px;
    height: 148px;
    width: 100%;
    max-width: none;
    &:last-child {
      margin-bottom: 0px;
    }
    @media ${bp.md} {
      max-width: 368px;
      height: 410px;
      margin-bottom: 32px;
      width: 32%;
    }
  `,
};

const InfoCard = ({ componentData }) => {
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = () => setWindowWidth(window.screen.width);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <TopicTile
      data={componentData}
      key={componentData.BlockComponentId}
      shouldShowImage={windowWidth >= 768}
      css={styles.topic}
    />
  );
};

InfoCard.propTypes = {};

InfoCard.defaultProps = {};

export default InfoCard;
