import { css } from '@emotion/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Sanitize from '@/src/shared/components/Sanitize';
import { colors } from '@/styles';

const { sanitize } = require('dompurify');

const INITIAL_WISTIA_DATA = {
  version: '',
  type: '',
  html: '',
};

const style = {
  iframeContainer: css`
    position: relative;
    padding-bottom: 56.25%;
    iframe {
      border: none;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  `,
  caption: css`
    font-size: 1.2rem;
    margin-top: 8px;
    color: ${colors.secondaryGray};
    line-height: 1.67;
  `,
};

const Video = ({ componentData }) => {
  const [wistiaData, setWistiaData] = useState(INITIAL_WISTIA_DATA);

  const fetchWistiaData = async Url => {
    try {
      const response = await axios({
        url: `https://fast.wistia.com/oembed?url=${Url}`,
      });
      if (response.data) {
        setWistiaData(response.data);
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    if (!componentData) return;
    const { Url } = componentData;
    fetchWistiaData(Url);
  }, []);

  const sanitized =
    typeof sanitize === 'function'
      ? sanitize(wistiaData.html, {
          ADD_TAGS: ['iframe'],
          FORCE_BODY: true,
          ADD_ATTR: ['target', 'href'],
          FORBID_ATTR: ['style'],
          FORBID_TAGS: ['style'],
        })
      : '';

  return (
    <div>
      <div css={style.iframeContainer} dangerouslySetInnerHTML={{ __html: sanitized }} />
      {componentData.ImageCaption && (
        <p css={style.caption}>
          <Sanitize>{componentData.ImageCaption}</Sanitize>
        </p>
      )}
    </div>
  );
};

Video.propTypes = {};

export default Video;
