import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';

import contentStyle from '@/src/cms/contentStyle';
import Sanitize from '@/src/shared/components/Sanitize';

const styles = {
  body: [contentStyle],
};

const Paragraph = ({ componentData }) => {
  return (
    <div css={styles.body}>
      <Sanitize>{componentData.Paragraph}</Sanitize>
    </div>
  );
};

Paragraph.propTypes = {};

export default Paragraph;
