import { css } from '@emotion/core';
import React from 'react';

import { bp } from '@/styles';

import Components from './Components';

const style = {
  row: css`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media ${bp.md} {
      margin: 0 auto;
      max-width: 1000px;
    }
  `,
};

const FullWidthParagraphVideo = ({ blockData, entities }) => {
  return (
    <div>
      {blockData.Components &&
        Array.isArray(blockData.Components) &&
        blockData.Components.map(c => (
          <div css={style.row} key={`bc-${c}`}>
            <Components componentData={entities.Components[c]} key={c} />
          </div>
        ))}
    </div>
  );
};

FullWidthParagraphVideo.propTypes = {};

export default FullWidthParagraphVideo;
